@import '../../helpers/mixin.scss';

.bgi-menu {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;

  width: 100vw;
  background-color: #fff;
  z-index: 2;
  overflow: hidden;
  
  @include onDesktop1280x720 {
    transform: scale(1.2);
  }

  &.open {
    position: fixed;
    width: 100vw;
    height: var(--bgi-menu\.open-height);
    overflow: hidden;
    opacity: 1;
    transition: height .8s ease-in;
  }

  &.closed {
    width: 100vw;
    height: 0;
    transition: height .8s ease-in;
  }

  &__top-bar__close {
    height: 20px;
    width: 100%;
    left: -12vw;
    top: -24vh;
    bottom: 0;

    @include onDesktop1280x720 {
      left: 0.5vh;
      top: -39vh;
    }

    &--button {
      position: fixed;
      display: none;
      height: 20px;
      width: 20px;
      top: 4vh;
      right: 7.5vw;

      animation: buttonAnimationOut 0.8s ease-in-out forwards;

      &.active {
        display: block;
        animation: buttonAnimationIn 0.8s ease-in-out forwards;
      }

      &:hover {
        cursor: pointer;
      }

      @include onPhone414x736 {
        top: 3.5vh;
        right: 7vw;
      }

      @include onPhone414x896 {
        top: 3vh;
      }

      @include onTablet1024x786 {
        height: 30px;
        width: 30px;
        right: 4.5vw;
        top: 3.5vh;
      }

      @include onDesktop1280x720 {
        height: 25px;
        width: 25px;
        left: 14vw;
        top: 12vh;
      }

      @include onDesktop1440x900 {
        left: 11vw;
        top: 7vw;
      }

      @include onDesktop1920x1080 {
        height: 30px;
        width: 30px;
        left: 14.5vw;
        top: 6.2vw;
      }
    }
  }


  &__header {
    position: relative;
    margin: 6px 6px 0px;
    padding: 0 2px 0;
    margin: 20px 0;
    
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 2px;
    text-decoration: none;
    text-wrap: nowrap;
    color: black;
    opacity: 1;

    @include onTablet1024x786 {
      font-size: 26px;
      margin: 35px 0px 35px;
    }

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -8px;
      height: 1px;
      background-color: rgb(9, 9, 9);
      transform: scaleX(0);
      transform-origin: 0 100%;
      transition: transform 0.5s;
    }

    &:hover::after,
    &.is-visible::after {
      transform: scaleX(1);
    }
  }

  &__items {
    max-height: 1px;
    overflow-y: hidden;
    transition: max-height 1.5s ease;

    &.is-visible {
      max-height: 100vh;
      transition: max-height 1.5s ease;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0;
    margin: 6px 6px 6px;
    list-style: none;
    transition: color 0.3s;

    &:hover {
      color: rgb(188, 188, 188);
      transition: color 0.3s;
    }

    &.is-active {
      color: rgb(212, 0, 0);
      transition: color 0.3s;
    }
  }

  &__label {
    cursor: pointer;
    font-size: 15px;
    margin: 4px 4px 4px;

    @include onTablet1024x786 {
      margin: 8px 0px 5px;
      font-size: 22px;
    }
  }
}

@keyframes buttonAnimationIn {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    display: block;
  }
}

@keyframes buttonAnimationOut {
  0% {
    display: block;
  }

  1% {
    opacity: 0;
    display: none;
  }
  100% {
    display: none;
  }
}