@import '../../helpers/mixin.scss';

.warning-modal {
  position: fixed;
  text-align: center;
  width: 80vw;
  top: 50%;
  left: 50%;
  padding: 30px;

  font-family: 'Grotesk', sans-serif;
  color: white;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  z-index: 9999;

  &__text {
    margin-bottom: 20px;
    font-size: 22px;
  }

  &__button {
    cursor: pointer;
    padding: 10px 20px;

    font-size: 16px;
    font-family: 'Grotesk', sans-serif;
    color: black;
    background-color: white;
    border: none;
  }
}

.opening {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;

  background-image: url(../../../public/images/main_upgrade.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 250% 130%;

  animation-name: zoomInBackground, changeBackgroundImage;
  animation-duration: 7s, 2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 11s;

  &.mobile-safari {
    height: 90vh;
  }
  
  &.mobile-chrome {
    height: 85vh;
  }

  @include onPhone360x780 {
    background-size: 250% 110%;
  }

  @include onPhone375x667 {
    background-size: 260% 130%;
  }

  @include onTablet1024x786 {
    background-size: 180% 120%;
  }

  @include onDesktop1280x720 {
    background-size: 100% 150%;
  }
}

.animated-phrase {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);

  white-space: pre;
  font-family: 'Nimbus', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: white;

  opacity: 0;

  transition: opacity 500ms ease-in-out;
  animation: fadeInAndOut 4.5s ease-in-out forwards;

  @include onPhone375x667 {
    font-size: 16px;
  }

  @include onPhone414x736 {
    font-size: 18px;
  }

  @include onTablet1024x786 {
    font-size: 24px;
  }

  @include onDesktop1280x720 {
    font-size: 22px;
  }

  @include onDesktop1920x1080 {
    font-size: 30px;
  }

  &.last-phrase {
    font-size: 20px;
    animation: fadeInAndOut 5s ease-in-out forwards;

    @include onPhone375x667 {
      font-size: 24px;
    }

    @include onTablet1024x786 {
      font-size: 30px;
    }

    @include onDesktop1920x1080 {
      font-size: 36px;
    }
  }
}

@keyframes zoomIn {
  to {
    transform: scale(100);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.95;
  }
}

@keyframes fadeInAndOut {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 0.95;
  }
}

@keyframes zoomInBackground {
  to {
    background-size: 1000% 750%;
  }
}

@keyframes changeBackgroundImage {
  to {
    background-image: url(../../../public/images/bgi_black.png);
  }
}