@import '../../helpers/mixin.scss';

.wraper__disquss {
  background: #efeded;
  height: 100%;
  width: 100%;
  position: relative;

  @include onDesktop1280x720 {
    height: 100vh;
  }
}

.custom-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 1%;

  height: auto;
  width: 80%;
  background-color: #fff;

  color: #000;
  font-family: "Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.5;
  border: 1px solid #000;

  &.top-bar-back {
    float: none;
    margin: 0;
    transform: none;
  }

  @include onTablet1024x786 {
    height: auto;
    margin-top: 20px;
    font-size: 22px;
  }

  @include onDesktop1280x720 {
    float: left;
    width: 40%;
    height: auto;

    margin-top: 80px;
    margin-left: 5%;
    font-size: 16px;
  }

  @include onDesktop1366x768 {
    font-size: 17px;
  }

  @include onDesktop1440x900 {
    margin-top: 100px;
    font-size: 18.8px;
  }

  @include onDesktop1920x1080 {
    font-size: 24px;
    margin-top: 130px;
  }
}

.discuss-container {
  height: 100%;

  margin-top: 25%;
  padding: 20px;
  padding-top: 60px;

  border: 1px solid #ccc;
  background-color: #ffffff;

  @include onPhone375x812 {
    height: 100vh;
  }

  @include onTablet1024x786 {
    height: 100%;
    margin-top: 10%;
  }

  @include onDesktop1280x720 {
    float: right;
    height: auto;
    width: 50%;

    margin-top: 0;
    padding: 35px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include onDesktop1366x768 {
    float: right;
    height: 100vh;
    width: 50%;

    padding-left: 35px;
    padding-right: 35px;
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

.wraper__disquss::after {
  content: "";
  display: table;
  clear: both;
}


.top-bar-back__dis {
  position: relative;
  display: flex;
  align-items: center;

  height: 100px;
  width: 80%;
  margin-left: 10%;
  transform: translate(0%, -15%);

  @include onDesktop1280x720 {
    width: 80%;
    height: 0;
    float: left;
    margin-left: 5%;
    transform: translateY(-1vh);
    border: 1px solid;
  }

  &--button {
    height: 40px;
    width: 80px;

    color: #000;
    font-family: 'Grotesk', sans-serif;
    font-size: 13px;
    font-weight: 600;
    border: 0.5px solid;
    border-color: #000000;

    background-color: #fff;
    transform: translate(0%, 50%);
    transition: border-color, 1s, ease-in;

    @include onDesktop1280x720 {
      float: left;
      height: 30px;
      width: 65px;
      transform: translateY(7vh);
      border: 0.5px solid;
    }

    &:hover {
      cursor: pointer;
      border: 0.5px solid;
      border-color: #a7a7a7;
    }

    @include onTablet1024x786 {
      height: 60px;
      width: 120px;
      font-size: 18px;
    }
  }
}