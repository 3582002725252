@mixin onPhone360x640 {
  @media (min-width: 360px)
  and (min-height: 640px)  
  {
    @content;
  }
}

@mixin onPhone360x780 {
  @media (min-width: 360px)
  and (min-height: 780px) 
  {
    @content;
  }
}

@mixin onPhone375x667 {
  @media (min-width: 375px) 
  and (min-height: 667px)
  {
    @content;
  }
}

@mixin onPhone375x812 {
  @media (min-width: 375px) 
  and (min-height: 812px)
  {
    @content;
  }
}

@mixin onPhone414x736 {
  @media (min-width: 414px)
  and (min-height: 736px) 
  {
    @content;
  }
}
@mixin onPhone414x896 {
  @media (min-width: 414px) 
  and (min-height: 896px) 
  {
    @content;
  }
}

@mixin onTablet1024x786 {
  @media (min-height: 1024px) 
  {
    @content;
  }
}

@mixin onDesktop1280x720 {
  @media (min-width: 1280px) 
   {
    @content;
  }
}
@mixin onDesktop1366x768 {
  @media (min-width: 1366px) 
   {
    @content;
  }
}

@mixin onDesktop1440x900 {
  @media (min-width: 1440px) 
  {
    @content;
  }
}

@mixin onDesktop1920x1080 {
  @media (min-width: 1920px)
  {
    @content;
  }
}
