@import '../../helpers/mixin.scss';

.round-button {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  
  opacity: 0.25;
  border-radius: 50%;
  background-color: #cd9191;
  border: 0.3px solid #000;
  
  cursor: pointer;
  animation: pulse 1s infinite alternate;
  transition: opacity 0.5s;

  @include onTablet1024x786 {
  border: 0.5px solid #000;
  }

  @include onDesktop1280x720 {
  border: 1px solid #000;
  }

  @include onDesktop1920x1080 {
  border: 1px solid #000;
  }

  &:hover {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1.1);
  }
}