@import '../../helpers/mixin.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000; 
}

.main {
  height: 100vh;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.container {
  position: relative;
  display: flex;
  align-items: flex-start;
  align-content: center;
  box-sizing: border-box;
  overflow-x: auto; 

  opacity: 0;
  transition: opacity 3s ease-in-out;

  @include onPhone414x896 {
    width: 100%;
    height: 896px;
  }

  @include onTablet1024x786 {
    width: 1025px;
    height: 1024px;
  }

  @include onDesktop1280x720 {
    justify-content: center;
    width: 1280px;
    height: 720px;
    position: inherit;

  }

  @include onDesktop1366x768 {
    width: 1366px;
    height: 768px;
  }

  @include onDesktop1440x900 {
    width: 1440px;
    height: 900px;
  }

  @include onDesktop1920x1080 {
    width: 1920px;
    height: 1080px;
  }

  &.show-image {
    overflow-y: hidden; 

    opacity: 1;
    transition: opacity 3s ease-in-out;
  }

  &.show-menu {
    overflow: auto;
  }
}


.image {
  display: flex;
  min-width: 1025px;
  overflow-x: auto;
  opacity: 1;
  transition: opacity 3s ease;

  &.show-image {
    opacity: 1;
    transition: opacity 2s ease-in;
  }

  @include onPhone360x780 {
    min-width: 1252px;
  }

  @include onPhone375x667 {
    min-width: 1070px;
  }

  @include onPhone375x812 {
    min-width: 1300px;
  }

  @include onPhone414x736 {
    min-width: 1180px;
  }

  @include onPhone414x896 {
    min-width: 1440px;
  }

  @include onTablet1024x786 {
    min-width: 1640px;
    height: 1024px;
  }

  @include onDesktop1280x720 {
    min-width: 1154px;
    height: 720px;
  }

  @include onDesktop1366x768 {
    min-width: 1231px;
    height: 768px;
  }

  @include onDesktop1440x900 {
    min-width: 1440px;
    height: 900px;
  }

  @include onDesktop1920x1080 {
    min-width: 1731px;
    height: 1080px;
  }
}

.burger-button {
  display: flex;
  position: fixed;

  width: 35px;
  height: 35px;
  right: 20px;
  top: 20px;

  font-size: 25px;
  background-color: #ffffff;
  border: 0.5px solid #000000;
  padding: 5px 5px;
  cursor: pointer;
  z-index: 2;

  &.is-active {
    z-index: 0;
  }

  @include onTablet1024x786 {
    height: 40px;
    width: 40px;
    right: 30px;
    top: 30px;
    font-size: 14px;
  }

  @include onDesktop1280x720 {
    top: 3vh;
    left: 7vw;
    transition: transform .3s ease-in;

    &:hover,
    &.is-active {
      transform: scale(1.15);
      transition: transform .3s ease-in;
    }
  }

  @include onDesktop1366x768 {
    width: 35px;
    height: 35px;
  }

  @include onDesktop1440x900 {
    width: 40px;
    height: 40px;
    left: 3vw;
  }

  @include onDesktop1920x1080 {
    width: 45px;
    height: 45px;
    left: 7vw;
  }

  &.is-active {
    border: 0px;
    content: "";
  }
}