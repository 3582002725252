@import '../../helpers/mixin.scss';

.topbar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 80vw; 
  height: 20vh;
  left: 50%;
  top: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);

  opacity: 0.85;

  font-family: 'Coarse Rounded', sans-serif;
  font-weight: 1000;
  font-size: 18px;
  color: #2b1500;
  background-color: #e2fcff;
  z-index: 9;
  box-shadow: 0 -8px 6px -6px rgb(110, 76, 47);
  -webkit-box-shadow: 0 -8px 6px -6px rgb(110, 76, 47);
  -moz-box-shadow: 0 -8px 6px -6px rgb(110, 76, 47);

  @include onPhone360x640 {
    transition: opacity .5s;
    &.hidden {
      opacity: 0;
    }
  }

  @include onTablet1024x786 {
    width: 70vw; 
    height: 20vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
  }

  @include onDesktop1280x720 {
    width: 30%; 
    height: 20%;
    font-size: 16px;
    transform: translate(-50%, -50%);
  }

  @include onDesktop1440x900 {
    font-size: 20px;
  }
}

.close-button {
  width: 15px;
  transform: translate(800%, -80%);
  &:hover {
    cursor: pointer;
  }

  @include onPhone360x780 {
    transform: translate(780%, -140%);
  }

  @include onPhone414x736 {
    transform: translate(880%, -110%);
  }

  @include onPhone414x896 {
    width: 20px;
    transform: translate(650%, -150%);
  }

  @include onTablet1024x786 {
    width: 22px;
    transform: translate(1000%, -100%);
  }

  @include onDesktop1280x720 {
    transform: translate(1000%, -100%);
  }

  @include onDesktop1440x900 {
    transform: translate(850%, -80%);
  }

  @include onDesktop1920x1080 {
    transform: translate(1000%, -120%);
  }
}

.icon {
  &-arrow {
    position: relative;
    width: 40px;
    height: 30px;
    margin-left: 20px;
    margin-top: 5px;
    transition: .3s ease-in-out;
    animation: pulse 1s infinite alternate;

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }

    @include onTablet1024x786 {
      width: 60px;
      height: 40px;
      margin-top: 10px;
    }
  }

  &-zoom {
    position: relative;
    width: 50px;
    height: 50px;
    transition: .3s ease-in-out;
    margin-left: 20px;
    animation: pulse 1s infinite alternate;

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  @include onTablet1024x786 {
    transform: scale(1.5);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.9);
  }
}