@import '../../helpers/mixin.scss';

.wraper__contacts {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: var(--wraper__contacts-height);

  background: #efeded;

  @include onTablet1024x786 {
    height: 100vh;
  }
}

.top-bar-back__contacts {
  position: relative;
  height: 0px;
  width: 80%;

  &--button {
    height: 40px;
    width: 80px;
    
    font-family: 'Grotesk', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #000;

    border: 0.5px solid;
    border-color: #000000;
    
    background-color: #fff;
    transform: translateY(-10vh);
    transition: border-color, 1s, ease-in;

    &:hover {
      cursor: pointer;
      border: 0.5px solid;
      border-color: #000;
      transition: border-color, 2s, ease-in;
    }

    @include onTablet1024x786 {
      height: 60px;
      width: 120px;
      font-size: 20px;
    }
  }
}

.contacts-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  height: 60%;
  width: 80%;

  font-family: 'Grotesk';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: .1px;
  line-height: 1.5;
  border: 1px solid #000;

  background-color: #fff;

  @include onTablet1024x786 {
    font-size: 22px;
  }

  @include onDesktop1280x720 {
    font-size: 24px;
    letter-spacing: .5px;
  }

  @include onDesktop1920x1080 {
    font-size: 26px;
  }
}

.contacts-text {
  margin: 10px;
  font-size: 19px;

  @include onTablet1024x786 {
    font-size: 22px;
  }

  @include onDesktop1280x720 {
    font-size: 22px;
  }

  @include onDesktop1920x1080 {
    font-size: 26px;
  }
}

.icons {
  width: 30px;
  margin: 15px;
  margin-top: 35px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  @include onTablet1024x786 {
    width: 35px;
  }
}