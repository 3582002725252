@import '../../helpers/mixin.scss';

.modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 200px;
  height: 140px;
  overflow: auto;
  backdrop-filter: blur(5px);
  font-family: 'Grotesk', sans-serif;

  
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1;
  
  @include onDesktop1280x720 {
    width: 200px;
    height: 120px;
  }

  &__name {
    font-size: 22px;
    margin: 10px 10px 10px;

    @include onDesktop1280x720 {
      font-size: 18px;
      margin: 7px 7px 5px;
    }
    @include onDesktop1920x1080 {
      font-size: 18px;
      margin: 7px 9px 5px;
    }
  }

  &__explanation {
    font-size: 20px;
    height: 100%;
    margin: 10px;
    padding-bottom: 5px;

    @include onDesktop1280x720 {
      font-size: 16px;
      margin: 5px 8px 0px;
    }
    @include onDesktop1920x1080 {
      font-size: 16px;
      margin: 5px 9px 0px;
    }
  }

  &.modal-open {
    opacity: 1;
    pointer-events: auto;
  }

  &.modal-closed {
    opacity: 0;
    pointer-events: none;
  }

  &.modal-closed .modal-content {
    max-width: 0;
  }
}

.modal-content {
  position: relative;
  max-width: 100%;
  height: 100%;

  background-color: rgba(64, 64, 64, 0.45);
  transition: max-width 2s;

  &-container {
    width: 100px;
    height: 50px;
    overflow-y: auto;
  }
}
